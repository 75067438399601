<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('table.search.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('table.search.entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-start mb-1 mb-md-0"
          >
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              :placeholder="$t('table.search.search')"
            />
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="export2excel"
            >
              <span class="text-nowrap">{{ $t('list.export') }}</span>
            </b-button>
            <b-button
              :to="{ name: 'apps-'+$t(this.RESOURCES_ELEMENT_NAME + '.module_name')+'-create' }"
              variant="primary"
            >
              <span class="text-nowrap">{{ $t(RESOURCES_ELEMENT_NAME + '.object.add') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refElementsListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')"
      >
        <!-- column: name -->
        <template #cell(name)="data">
          <b-badge
            pill
            class="text-capitalize"
          >
            {{ data.item.name }}
          </b-badge>
        </template>
        <!-- Column: key -->
        <template #cell(generic_name)="data">
          {{ data.item.generic_name }}
        </template>
        <template #cell(cp)="data">
          <span
            v-if="is_source_positive(data.item)"
            :id="`source-positive-${data.item.id}`"
            class="dot_purple"
          />
          <span
            v-if="is_patient_positive(data.item)"
            :id="`patient-positive-${data.item.id}`"
            class="dot_red"
          />

          <b-tooltip
            :target="`source-positive-${data.item.id}`"
            triggers="hover"
          >
            <span class="align-middle ml-50">{{ $t(RESOURCES_ELEMENT_NAME + '.source_positive') }}</span>
          </b-tooltip>
          <b-tooltip
            :target="`patient-positive-${data.item.id}`"
            triggers="hover"
          >
            <span class="align-middle ml-50">{{ $t(RESOURCES_ELEMENT_NAME + '.patient_positive') }}</span>
          </b-tooltip>
        </template>
        <template #cell(laboratory)="data">
          {{ data.item.laboratory }}
        </template>
        <template #cell(description)="data">
          {{ data.item.description }}
        </template>
        <template #cell(accident_date)="data">
          {{ formatDate(data.item, 'accident_date') }}
        </template>
        <template #cell(report_date)="data">
          {{ formatDate(data.item, 'report_date') }}
        </template>
        <!-- Column: Status -->
        <!-- Column: actions -->
        <template #cell(actions)="data">
          <b-col>
            <b-button
              :id="`view-button-${data.item.id}`"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              :to="{ name: 'apps-'+$t(RESOURCES_ELEMENT_NAME + '.module_name')+'-view', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />

            </b-button>

            <b-button
              :id="`edit-button-${data.item.id}`"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              :to="{ name: 'apps-'+$t(RESOURCES_ELEMENT_NAME + '.module_name')+'-follow-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-button
              :id="`medical-record-button-${data.item.id}`"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              :to="{ name: 'apps-medical-history-view', params: { case_id: data.item.id, patient_id: data.item.patient_id } }"
            >
              <feather-icon icon="ListIcon" />
            </b-button>

            <b-button
              :id="`logbook-button-${data.item.id}`"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              :to="{ name: 'apps-logbook-list', params: { case_id: data.item.id, origin:'apps-case-following-list' } }"
            >
              <feather-icon icon="FileIcon" />
            </b-button>

            <b-button
              :id="`medical-order-${data.item.id}`"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              :to="{ name: 'apps-medical-order', params: { case_id: data.item.id, origin:'apps-case-following-list' } }"
            >
              <feather-icon icon="CrosshairIcon" />
            </b-button>

            <b-tooltip
              :target="`view-button-${data.item.id}`"
              triggers="hover"
            >
              <span class="align-middle ml-50">{{ $t('actions.details') }}</span>
            </b-tooltip>
            <b-tooltip
              :target="`edit-button-${data.item.id}`"
              triggers="hover"
            >
              <span class="align-middle ml-50">{{ $t('actions.edit') }}</span>
            </b-tooltip>
            <b-tooltip
              :target="`medical-record-button-${data.item.id}`"
              triggers="hover"
            >
              <span class="align-middle ml-50">{{ $t('actions.medical_history') }}</span>
            </b-tooltip>
            <b-tooltip
              :target="`logbook-button-${data.item.id}`"
              triggers="hover"
            >
              <span class="align-middle ml-50">{{ $t('actions.logbook') }}</span>
            </b-tooltip>
            <b-tooltip
              :target="`medical-order-${data.item.id}`"
              triggers="hover"
            >
              <span class="align-middle ml-50">{{ $t('actions.medical_order') }}</span>
            </b-tooltip>
          </b-col>
        </template>

      </b-table>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <div>
      <b-modal
        id="modal-1"
        hide-footer
        :title="$t('actions.delete')"
      >
        <template>
          <delete-confirmation
            :value-name="currentSelection.name"
            :resources-element-name="RESOURCES_ELEMENT_NAME"
            class="mt-2 pt-75"
          />
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import * as XLSX from 'xlsx'
import moment from 'moment'
import ElementStoreModule from '../storeModule'
import Filters from './filters.vue'
import DeleteConfirmation from '../../../core/DeleteConfirmation.vue'

export default {

  components: {
    DeleteConfirmation,
    Filters,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      selected_element: {},
      currentSelection: {},
      tableColumns: [
      ],
    }
  },
  watch: {
    perPage(val) {
      this.fetchElements()
    },
    currentPage(val) {
      this.fetchElements()
    },
    query(val) {
      this.currentPage = 1
      this.fetchElements()
    },
  },
  beforeDestroy() {
    this.$root.$off('delete-entity')
  },
  mounted() {
    this.RESOURCES_ELEMENT_NAME = 'case'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-list-follow-${this.RESOURCES_ELEMENT_NAME}`
    this.tableColumns = [
      { key: 'cp', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.identificator`), sortable: true },
      { key: 'id', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.id`), sortable: true },
      { key: 'case_number', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.number`), sortable: true },
      { key: 'patient_identity', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.patient_identity`), sortable: true },
      { key: 'patient_name', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.patient_name`), sortable: true },
      { key: 'accident_date', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.accident_date`), sortable: true },
      { key: 'report_date', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.report_date`), sortable: true },
      { key: 'arl_name', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.arl_name`), sortable: true },
      { key: 'case_type_name', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.case_type_name`), sortable: true },
      { key: 'case_status_name', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.case_status_name`), sortable: true },
      { key: 'actions', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.actions`), thStyle: 'width: 300px;' },
    ]
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)

    this.fetchElements()

    this.$root.$on('delete-entity', () => {
      console.log('eliminando')

      const _self = this
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/delete_element`, { id: _self.currentSelection.id })
        .then(response => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t('actions.result.delete.title'),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t('actions.result.delete.success'),
            },
          })
          _self.fetchElements()
        })
        .catch(error => {
          _self.fetchElements()
          // _self.$toast({
          //   component: ToastificationContent,
          //   position: 'top-right',
          //   props: {
          //     title: _self.$t('actions.result.delete.title'),
          //     icon: 'SaveIcon',
          //     variant: 'danger',
          //     text: _self.$t('actions.result.delete.failure'),
          //   },
          // })
        })
    })
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    is_source_positive(element) {
      return element.source_positive
    },
    is_patient_positive(element) {
      return element.patient_positive
    },
    formatDate(element, field) {
      return moment(element[field], 'DD/MM/YYYY').format('DD/MM/YYYY')
    },
    delete_entity(selected_element) {
      this.currentSelection = selected_element
      this.$root.$emit('bv::show::modal', 'modal-1')
    },
    filterJson(jsonData) {
      const filterList = this.tableColumns.map(a => a.key)
      filterList.pop()
      return jsonData.map(v => filterList.map(j => v[j]))
    },
    getHeader() {
      const res = {}
      for (let i = 0; i < this.tableColumns.length; i++) {
        res[this.tableColumns[i].key] = this.tableColumns[i].label
      }
      return res
    },
    format(jsonData) {
    },
    async export2excel() {
      const filename = this.$t(`${this.RESOURCES_ELEMENT_NAME}.following_xls_name`)
      const result = await store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_elements`, {
        queryParams: {
          perPage: 10000,
          page: 1,
          query: this.query,
        },
      })
      this.format(result.data.items)
      result.data.items.unshift(this.getHeader())
      const list = this.filterJson(result.data.items)
      const data = XLSX.utils.json_to_sheet(list, { skipHeader: true })
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    fetchElements() {
      console.info(this.currentPage)

      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_elements_support`, {
          queryParams: {
            perPage: this.perPage,
            page: this.currentPage,
            query: this.query,
          },
        })
        .then(response => {
          this.items = response.data.items
          this.total = response.data.total
        })
        .catch(() => {

        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.dot_red {
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.dot_purple {
  height: 25px;
  width: 25px;
  background-color: purple;
  border-radius: 50%;
  display: inline-block;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
